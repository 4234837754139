import "../scss/main.scss";
if (process.env.NODE_ENV === "production") {
  function requireAll(r) {
    r.keys().forEach(r);
  }
  requireAll(require.context("../svg/", true, /\.svg$/));
}

import "./main"

